import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import axios from 'axios'
import Header from 'components/Header'
import url from 'config/server-url.jsx'
import FlexBetween from 'components/FlexBetween'
import { Box, Button, useTheme } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom' // Import useParams


const ActiveCourses = () => {
  const [courseList, setCourseList] = useState([])
  const theme = useTheme()
  const navigate = useNavigate() // Initialize useNavigate
  const { courseId } = useParams() // Get courseId from URL parameters

  const columnFlex = 1
  const columns = [
    {
      field: 'courseName',
      headerName: 'Course Name',
      flex: columnFlex,
      renderCell: params => params.row.courseName
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: columnFlex,
      sortable: false,
      renderCell: params => (
        <>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleEditItem(params.row._id)}
          >
            Edit
          </Button>

          <Button
            variant='contained'
            color='success'
            sx={{ marginX: '5px' }}
            onClick={() => handleEditItemSave(params.row._id, 'archived')}
          >
            Archive
          </Button>

          <Button
            variant='contained'
            color='secondary'
            onClick={() => handleEditItemSave(params.row._id, 'deleted')}
          >
            Delete
          </Button>
        </>
      )
    }
  ]

  const handleEditItem = courseId => {
    navigate(`/update course/${courseId}`)
  }

  const handleEditItemSave = async (courseId, newCourseStatus) => {
    try {
      const response = await axios.put(
        url + `api/newCourse/updateCourseStatus`,
        { courseId, courseStatus: newCourseStatus }
      )

      if (response.status === 200) {
        getActiveCourses()
      }
    } catch (error) {
      console.error('Error editing item:', error)
    }
  }

  const getActiveCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(
        url + 'api/newCourse/getCoursesByStatus',
        {
          adminId,
          courseStatus: 'active'
        }
      )
      setCourseList(response.data)
    } catch (error) {
      console.error('Error getting active courses:', error)
    }
  }

  useEffect(() => {
    getActiveCourses()
  }, [])

  return (
    <Box m='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='Active Courses' />
      </FlexBetween>

      <Box
        gap='20px'
        display='grid'
        gridAutoRows='160px'
        gridTemplateColumns='repeat(12, 1fr)'
      >
        <Box
          gridColumn='span 12'
          gridRow='span 3'
          my='20px'
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
              boxShadow: 4,
              backgroundColor: theme.palette.background.normal
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.primary.main,
              color:
                theme.palette.mode === 'light'
                  ? '#FFFFFF'
                  : theme.palette.background.main,
              border: 'none',
              borderRadius: 0,
              boxShadow: 5
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: theme.palette.background,
              color: theme.palette.secondary[100],
              borderTop: 'none'
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${theme.palette.secondary} !important`
            }
          }}
        >
          <DataGrid
            autoHeight
            rows={courseList}
            columns={columns}
            getRowId={row => row._id}
            components={{
              Toolbar: GridToolbar
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ActiveCourses
