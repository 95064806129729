import React, { useState, useEffect } from 'react'
import axios from 'axios'
import url from '../../config/server-url'
import { Box } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

const ImageCarousel = ({ imageUrls }) => {
  const [signedUrls, setSignedUrls] = useState({})

  const getSignedUrl = async fileUrl => {
    try {
      const urlParts = fileUrl.split('/')
      const fileName = urlParts[urlParts.length - 1]
      const response = await axios.post(`${url}api/s3`, {
        fileName,
        fileType: 'image/jpeg'
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchSignedUrls = async () => {
      const newSignedUrls = {}
      for (const image of imageUrls) {
        if (!signedUrls[image.url]) {
          newSignedUrls[image.url] = await getSignedUrl(image.url)
        }
      }
      setSignedUrls(prev => ({ ...prev, ...newSignedUrls }))
    }
    fetchSignedUrls()
  }, [imageUrls])

  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      <Carousel navButtonsAlwaysVisible>
        {imageUrls.map((res, index) => (
          <Box
            key={index}
            component='img'
            src={signedUrls[res.url] || ''}
            alt={`Slide ${index + 1}`}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        ))}
      </Carousel>
    </Box>
  )
}

export default ImageCarousel
