import React, { useState, useEffect } from 'react'
import axios from 'axios'
import url from '../../config/server-url'
import { Box, Typography } from '@mui/material'

const DocumentViewer = ({ documents }) => {
  const [signedUrls, setSignedUrls] = useState({})

  const getSignedUrl = async fileUrl => {
    try {
      const urlParts = fileUrl.split('/')
      const fileName = urlParts[urlParts.length - 1]
      const response = await axios.post(`${url}api/s3`, {
        fileName,
        fileType: 'application/pdf'
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  const handleDocumentClick = async document => {
    if (!signedUrls[document.url]) {
      const signedUrl = await getSignedUrl(document.url)
      console.log('signedUrl', signedUrl)
      setSignedUrls(prev => ({ ...prev, [document.url]: signedUrl }))
    }
    window.open(signedUrls[document.url] || document.url, '_blank')
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      sx={{ marginTop: '30px' }}
    >
      {documents.map((res, index) => (
        <Box
          key={index}
          marginBottom='20px'
          width='100%'
          textAlign='left'
          onClick={() => handleDocumentClick(res)}
          style={{ cursor: 'pointer' }}
        >
          <Typography
            variant='body1'
            component='span'
            sx={{ color: 'black', textDecoration: 'none', marginRight: '10px' }}
          >
            {index + 1}.
          </Typography>
          <Typography
            variant='body1'
            component='span'
            sx={{ color: 'blue', textDecoration: 'none' }}
          >
            {res.name}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default DocumentViewer
