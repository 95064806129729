import React, { useState, useEffect } from 'react'
import axios from 'axios'
import url from '../../config/server-url'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'

const AudioPlayerWithPlaylist = ({ audioFiles }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0)
  const [signedUrls, setSignedUrls] = useState({})

  const getSignedUrl = async fileUrl => {
    try {
      const urlParts = fileUrl.split('/')
      const fileName = urlParts[urlParts.length - 1]
      const response = await axios.post(`${url}api/s3`, {
        fileName,
        fileType: 'audio/mpeg'
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (!signedUrls[audioFiles[currentTrackIndex].url]) {
        const signedUrl = await getSignedUrl(audioFiles[currentTrackIndex].url)
        setSignedUrls(prev => ({
          ...prev,
          [audioFiles[currentTrackIndex].url]: signedUrl
        }))
      }
    }
    fetchSignedUrl()
  }, [currentTrackIndex, audioFiles])

  const handleTrackChange = index => {
    setCurrentTrackIndex(index)
  }

  return (
    <Box display='flex' sx={{ paddingTop: '20px' }}>
      <Box flex={2} marginRight={2}>
        <Typography variant='h4' gutterBottom>
          {audioFiles[currentTrackIndex].name}
        </Typography>
        <audio
          controls
          src={signedUrls[audioFiles[currentTrackIndex].url] || ''}
          style={{ width: '100%' }}
        >
          Your browser does not support the audio element.
        </audio>
      </Box>
      <Box flex={1}>
        <Typography variant='h4' gutterBottom>
          Playlist
        </Typography>
        <List>
          {audioFiles.map((res, index) => (
            <ListItem
              button
              selected={index === currentTrackIndex}
              onClick={() => handleTrackChange(index)}
              key={index}
            >
              <ListItemAvatar>
                <Avatar>
                  <MusicNoteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={res.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default AudioPlayerWithPlaylist
