import React, { useState, useEffect } from 'react'
import axios from 'axios'
import url from '../../config/server-url'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material'
import ReactPlayer from 'react-player'

const thumbnails = [
  'https://via.placeholder.com/150?text=Video+1',
  'https://via.placeholder.com/150?text=Video+2',
  'https://via.placeholder.com/150?text=Video+3'
]

const VideoPlayerWithPlaylist = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const [signedUrls, setSignedUrls] = useState({})

  const getSignedUrl = async fileUrl => {
    try {
      const urlParts = fileUrl.split('/')
      const fileName = urlParts[urlParts.length - 1]
      const response = await axios.post(`${url}api/s3`, {
        fileName,
        fileType: 'video/mp4'
      })
      return response.data.signedUrl
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (!signedUrls[videos[currentVideoIndex].url]) {
        const signedUrl = await getSignedUrl(videos[currentVideoIndex].url)
        setSignedUrls(prev => ({
          ...prev,
          [videos[currentVideoIndex].url]: signedUrl
        }))
      }
    }
    fetchSignedUrl()
  }, [currentVideoIndex, videos])

  const handleVideoSelection = index => {
    setCurrentVideoIndex(index)
  }

  return (
    <Box display='flex' sx={{ paddingTop: '20px' }}>
      <Box flex={2} marginRight={2}>
        <Typography variant='h4' gutterBottom>
          {videos[currentVideoIndex].name}
        </Typography>
        <ReactPlayer
          url={signedUrls[videos[currentVideoIndex].url] || ''}
          controls
          width='100%'
          height='auto'
        />
      </Box>
      <Box flex={1}>
        <Typography variant='h4' gutterBottom>
          Playlist
        </Typography>
        <List>
          {videos.map((res, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleVideoSelection(index)}
              selected={index === currentVideoIndex}
            >
              <ListItemAvatar>
                <Avatar
                  src={thumbnails[index]}
                  alt={`Thumbnail for video ${index + 1}`}
                />
              </ListItemAvatar>
              <ListItemText primary={res.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default VideoPlayerWithPlaylist
