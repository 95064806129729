import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import url from 'config/server-url'
import Header from 'components/Header'
import Button from '@mui/material/Button'
import FlexBetween from 'components/FlexBetween'
import ImageCarousel from 'scenes/resourceViewers/image'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DocumentViewer from 'scenes/resourceViewers/document'
import VideoPlayerWithPlaylist from 'scenes/resourceViewers/video'
import AudioPlayerWithPlaylist from 'scenes/resourceViewers/audio'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, useTheme } from '@mui/material'
import { IconButton } from '@mui/material'

const Dashboard = () => {
  const theme = useTheme()

  const roleValue = localStorage.getItem('role')

  const [role, setRole] = useState('')
  const [courseList, setCourseList] = useState([])
  const [studentList, setStudentList] = useState([])
  const [courseDetails, setCourseDetails] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedChapterId, setSelectedChapterId] = useState(null)
  const [selectedVideoCategory, setSelectedVideoCategory] = useState(null)
  const [selectedImageCategory, setSelectedImageCategory] = useState(null)
  const [selectedAudioCategory, setSelectedAudioCategory] = useState(null)
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState(null)

  const dataGrid = (columns, rows) => {
    return (
      <Box
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            boxShadow: 4,
            backgroundColor: theme.palette.background.normal
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color:
              theme.palette.mode === 'light'
                ? '#FFFFFF'
                : theme.palette.background.main,
            border: 'none',
            borderRadius: 0,
            boxShadow: 5
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background,
            color: theme.palette.secondary[100],
            borderTop: 'none'
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary} !important`
          }
        }}
      >
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          getRowId={row => row._id}
          components={{
            Toolbar: GridToolbar
          }}
        />
      </Box>
    )
  }

  const courseColumns = useMemo(
    () => [
      {
        field: 'courseName',
        headerName: 'Course Name',
        flex: 1
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        renderCell: params => (
          <IconButton
            color='primary'
            onClick={() => {
              setSelectedCourse(params.row)
              getCourseStudents(params.row._id)
            }}
          >
            <VisibilityIcon />
          </IconButton>
        )
      }
    ],
    []
  )

  const studentColumns = useMemo(
    () => [
      {
        field: 'studentName',
        headerName: 'Student Name',
        flex: 1,
        renderCell: params => (
          <Button
            variant='text'
            onClick={() => {
              setSelectedStudent(params.row)
              getCourseDetails(params.row.courseId)
            }}
          >
            {params.row.studentName}
          </Button>
        )
      },
      {
        field: 'studentContact',
        headerName: 'Contact',
        flex: 1
      },
      {
        field: 'studentAddress',
        headerName: 'Address',
        flex: 1
      },
      {
        field: 'studentStatus',
        headerName: 'Status',
        flex: 1
      }
    ],
    []
  )

  const getAdminCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const getCourses = await axios.post(
        url + 'api/newCourse/getAdminCourses',
        { adminId: adminId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseList(getCourses.data)
    } catch (error) {
      console.error('Error getting courses:', error)
    }
  }

  const getCourseStudents = async courseId => {
    try {
      const getStudents = await axios.post(
        url + 'api/students/getCourseStudents',
        { courseId: courseId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setStudentList(getStudents.data)
    } catch (error) {
      console.error('Error getting students:', error)
    }
  }

  const getCourseDetails = async courseId => {
    console.log('courseId')
    console.log(courseId)
    try {
      const courseDetailsResponse = await axios.post(
        url + 'api/students/getStudentCourseDetails',
        { courseId: courseId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      setCourseDetails(courseDetailsResponse.data)
    } catch (error) {
      console.error('Error getting course details:', error)
    }
  }

  const getSignedUrl = async key => {
    try {
      const response = await axios.get(
        `${url}api/s3?key=${encodeURIComponent(key)}`
      )
      return response.data.url
    } catch (error) {
      console.error('Error fetching signed URL:', error)
      return null
    }
  }

  useEffect(() => {
    if (roleValue === 'student') {
      setRole('student')
      getCourseDetails(localStorage.getItem('courseId'))
    } else {
      getAdminCourses()
    }
  }, [])

  const renderResourceCell = params => {
    const resourceCount = params.value
    const circleStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '18px',
      height: '18px',
      borderRadius: '50%',
      backgroundColor: resourceCount > 0 ? '#01C001' : 'grey',
      color: 'white',
      cursor: resourceCount > 0 ? 'pointer' : 'default'
    }

    const handleCircleClick = () => {
      if (resourceCount > 0) {
        setSelectedChapterId(params.row._id)
        const category = courseDetails.categories.find(
          cat => cat.categoryName === params.field
        )

        if (category) {
          const fileUrls = category.resources
            .filter(res => res.chapterId === params.row._id)
            .map(res => ({
              name: res.resourceName,
              url: res.fileLink // This is now the signed URL
            }))
            .filter(url => url.url !== 'No file uploaded.' && url.url !== null)

          if (category.fileType === 'Image') {
            if (fileUrls.length > 0) {
              setSelectedImageCategory(fileUrls)
            }
          }

          if (category.fileType === 'Video') {
            if (fileUrls.length > 0) {
              setSelectedVideoCategory(fileUrls)
            }
          }

          if (category.fileType === 'Audio') {
            if (fileUrls.length > 0) {
              setSelectedAudioCategory(fileUrls)
            }
          }

          if (category.fileType === 'Document') {
            if (fileUrls.length > 0) {
              setSelectedDocumentCategory(fileUrls)
            }
          }
        }
      }
    }

    return (
      <Box sx={circleStyle} onClick={handleCircleClick}>
        {resourceCount > 0 ? resourceCount : ''}
      </Box>
    )
  }

  const renderCourseDetails = () => {
    console.log('courseDetails')
    console.log(courseDetails)
    if (!courseDetails) return null

    const { course, chapters, categories } = courseDetails

    const rows = chapters.map(chapter => {
      const row = {
        _id: chapter._id,
        chapterName: chapter.chapterName
      }

      categories.forEach(category => {
        const resourcesCount = category.resources.filter(
          res => res.chapterId === chapter._id
        ).length
        row[category.categoryName] = resourcesCount
      })

      return row
    })

    const columns = [
      { field: 'chapterName', headerName: 'Chapter Name', flex: 1 },
      ...categories.map(category => ({
        field: category.categoryName,
        headerName: category.categoryName,
        flex: 1,
        renderCell: renderResourceCell
      }))
    ]

    return (
      <Box
        width='100%'
        sx={{
          marginBottom: '30px'
        }}
      >
        <Box
          sx={{
            padding: '20px',
            display: 'flex',
            borderRadius: '5px',
            marginBottom: '20px',
            flexDirection: 'row',
            backgroundColor: '#FFFFFF',
            justifyContent: 'space-between',
            boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)'
          }}
        >
          <h2>Course: {course.courseName}</h2>
          {role !== 'Student' && selectedStudent && (
            <h2>Student: {selectedStudent.studentName}</h2>
          )}
        </Box>
        {dataGrid(columns, rows)}
      </Box>
    )
  }

  const isResourceViewerActive =
    selectedDocumentCategory ||
    selectedVideoCategory ||
    selectedImageCategory ||
    selectedAudioCategory

  return (
    <Box p='20px'>
      <FlexBetween>
        <Header title='Admin Courses' />
        {((role === 'student' && isResourceViewerActive) || selectedCourse) && (
          <Button
            startIcon={<ArrowBackIcon />}
            variant='contained'
            color='primary'
            onClick={() => {
              if (!isResourceViewerActive) {
                setSelectedCourse(null)
                setSelectedStudent(null)
                setCourseDetails(null)
              } else {
                setSelectedDocumentCategory(null)
                setSelectedVideoCategory(null)
                setSelectedImageCategory(null)
                setSelectedAudioCategory(null)
              }
            }}
          >
            {!isResourceViewerActive ? 'Courses' : 'Course Details'}
          </Button>
        )}
      </FlexBetween>

      {role === 'student' ? (
        !isResourceViewerActive ? (
          renderCourseDetails()
        ) : (
          <>
            {selectedImageCategory && (
              <ImageCarousel imageUrls={selectedImageCategory} />
            )}
            {selectedVideoCategory && (
              <VideoPlayerWithPlaylist videos={selectedVideoCategory} />
            )}
            {selectedAudioCategory && (
              <AudioPlayerWithPlaylist audioFiles={selectedAudioCategory} />
            )}
            {selectedDocumentCategory && (
              <DocumentViewer documents={selectedDocumentCategory} />
            )}
          </>
        )
      ) : (
        <>
          {!selectedCourse ? (
            dataGrid(courseColumns, courseList)
          ) : !selectedStudent ? (
            dataGrid(studentColumns, studentList)
          ) : !isResourceViewerActive ? (
            renderCourseDetails()
          ) : (
            <>
              {selectedImageCategory && (
                <ImageCarousel imageUrls={selectedImageCategory} />
              )}
              {selectedVideoCategory && (
                <VideoPlayerWithPlaylist videos={selectedVideoCategory} />
              )}
              {selectedAudioCategory && (
                <AudioPlayerWithPlaylist audioFiles={selectedAudioCategory} />
              )}
              {selectedDocumentCategory && (
                <DocumentViewer documents={selectedDocumentCategory} />
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default Dashboard
