import React, { useState, useEffect } from 'react'
import axios from 'axios'
import url from 'config/server-url'
import Header from 'components/Header'
import FlexBetween from 'components/FlexBetween'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Button, useTheme } from '@mui/material'

const ArchivedCourses = () => {
  const [allStudents, setAllStudents] = useState([])
  const theme = useTheme()
  const columnFlex = 1
  const columns = [
    { field: 'courseName', headerName: 'Course Name', flex: columnFlex },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: columnFlex,
      sortable: false,
      renderCell: params => (
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleUnarchive(params.row._id)}
        >
          Un-archive
        </Button>
      )
    }
  ]

  useEffect(() => {
    getArchivedCourses()
  }, [])

  const getArchivedCourses = async () => {
    try {
      const adminId = localStorage.getItem('admin_id')
      const response = await axios.post(
        url + 'api/newCourse/getCoursesByStatus',
        { adminId, courseStatus: 'archived' }
      )
      if (response.status === 200) {
        const updatedData = response.data.map(student => ({
          ...student,
          addcourse: student.addcourse
        }))
        setAllStudents(updatedData)
      } else {
        throw new Error('Error fetching archived courses')
      }
    } catch (error) {
      console.error('Error fetching archived courses:', error)
    }
  }

  const handleUnarchive = async (courseId) => {
    try {
      const response = await axios.put(
        url + `api/newCourse/updateCourseStatus`,
        { courseId, courseStatus: 'active' }
      )
      if (response.status === 200) {
        getArchivedCourses()
      }
    } catch (error) {
      console.error('Error unarchiving course:', error)
    }
  }

  return (
    <Box m='1.5rem 2.5rem'>
      <FlexBetween>
        <Header title='Archived Courses' />
      </FlexBetween>

      <Box
        display='grid'
        gridTemplateColumns='repeat(12, 1fr)'
        gridAutoRows='160px'
        gap='20px'
      >
        <Box
          gridColumn='span 12'
          gridRow='span 3'
          my='20px'
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
              boxShadow: 4,
              backgroundColor: theme.palette.background.normal
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none'
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.primary.main,
              color:
                theme.palette.mode === 'light'
                  ? '#FFFFFF'
                  : theme.palette.background.main,
              border: 'none',
              borderRadius: 0,
              boxShadow: 5
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: theme.palette.background,
              color: theme.palette.secondary[100],
              borderTop: 'none'
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${theme.palette.secondary} !important`
            }
          }}
        >
          <DataGrid
            rows={allStudents}
            columns={columns}
            getRowId={row => row._id}
            components={{
              Toolbar: GridToolbar
            }}
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ArchivedCourses